<template>
  <v-card class="confirm pa-5 d-flex flex-column ma-2" 
    max-width="600" 
    max-height="450"
    max-md-height="600"
    elevation="1"
    tile >
      <h2 class="title-3 mb-5 mt-1">{{ question }}</h2>

      <section v-if="$vuetify.breakpoint.mdAndUp" class="list px-1">
        <ListItem v-for="(item, i) in items" :key="item.id" 
          :item="item" 
          :mode="'low'"
          class="app-card"
          :class="{ 'app-card_last': i === items.length - 1 }" />
      </section>

      <section v-else class="list px-1">
        <ListItemMobile v-for="(item, i) in items" :key="item.id" 
          :item="item" 
          :mode="'low'"
          class="app-card"
          :class="{ 'app-card_last': i === items.length - 1 }" />
      </section>

      <v-row class="mx-0 mt-5 mb-1">
        <v-spacer></v-spacer>
        <v-btn class="mr-4"
          elevation="0"
          color="#0a467e"
          outlined
          tile
          @click="$emit('close')" >
            Отменить
        </v-btn>

        <v-btn color="#0a467e"
          elevation="0"
          dark
          tile
          @click="$emit('agree', listID); $emit('close')" >
            Подтвердить
        </v-btn>
      </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'Confirm',
  props: {
    items: {
      type: Array,
      required: true,
    },
    question: {
      type: String,
      required: true,
    },
  },
  components: { 
    ListItem: () => import('@/components/promo/PromoListItem'),
    ListItemMobile: () => import('@/components/promo/PromoListItemMobile')
  },
  computed: {
    listID: function() {
      let arr = [];
      this.items.forEach(obj => {
        arr.push(obj.id)
      })
      return arr;
    }
  },
}
</script>

<style lang='scss' scoped>
.title-3 {
  font-size: 20px;
  line-height: 27px;
  font-weight: 700;
  color: #56565A;
}

.list {
  max-height: 100%;
  overflow: auto;
}

.app-card {
  border-left: 1px solid #dadada !important;
  border-right: 1px solid #dadada !important;
  border-top: 1px solid #dadada !important;

  &_last {
    border-bottom: 1px solid #dadada !important;
  }

  .app-card__checkbox * {
    user-select: none;
  }
}
</style>
